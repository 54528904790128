/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import React from 'react';
import * as ReactDOM from 'react-dom';
import { Component, createRef } from 'react';
import lscache from 'lscache';
import JWPlayer from '../../libs/jwplayer-react/jwplayer';
import { liveBlogTracking } from '../live-blog/tracking';
import NBCTruncate from '../nbc-truncate';

import {
  VideoPlayerProps,
  Captions,
  VideoPlayerState,
  formatTime,
  isIOS,
  prioritizeNativeCaption,
  isAutoplayBlocked,
  isMobile,
  isSafari,
  isMobileDevice,
  getLanguage,
  getAnalyticsMeta,
  getUrlParam,
  getFwSSIDSuffix,
  isIpad,
  setFreewheelCSID,
  setContentSections,
  setXumoParameters,
  formatSyndicatedID,
  primaryCategory,
  unifiedMpidCheck,
} from './utils';
import { SubtitleSettings, defaultSubtitleSettings } from './components/subtitle-settings-panel';
import ShareCard from './components/share-card';
import {
  lineSettings,
  backgroundColorMap,
  opacityMap,
  textMap,
  containerSettings,
  captionFontSizeSettings,
} from './components/subtitle-settings-utils';

declare global {
  interface Window {
    wp: any;
    nbc: any;
    PARSELY: any;
    ns_: any;
    mParticle: any;
  }
}

const {
  wp: {
    i18n: {
      __,
    },
  },
  ns_: {
    StreamingAnalytics: {
      JWPlayer: ComscoreJWP = {},
    } = {},
  } = {},
  PARSELY = {},
  nbc,
} = window;

const {
  nbc: {
    callLetters,
    pubdate,
    author = '',
    dataLayer: {
      pageName = '',
      sensitive: sensitiveContent,
      template,
      isAmpVideo = false,
    },
    video: {
      jwPlayerKey = 'lm6n3gQvnOELdUrKN4c+LMs/kvO7T3PqQrSVpbi54cz0md2f',
      fwAdManagerVersion = '',
      fwSSID: siteSectionId = '',
      fwNetworkID = '382114',
      fwManager: {
        siteKey: fwSiteKey = '',
      },
      fwDesktopSFIDVod = '',
      fwDesktopSFIDLive = '',
      fwMobileSFIDVod = '',
      fwMobileSFIDLive = '',
      fwAFID = '',
      playerType = '',
    },
    comscore_c4: comscoreC4,
    market: nbcMarket,
    env,
    fwunification,
    isLiveBlog,
  },
} = window;

const storedCaptionSettings = lscache.get('captionSettings');

class VideoPlayer extends Component<VideoPlayerProps, VideoPlayerState> {
  public static defaultProps = {
    continuousPlay: 'first',
  };

  private videoRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  player: any;

  jwPlayerId = '';

  lastProgressUpdate = 0;

  freewheel = {};

  comscoreMeta = {
    publisherId: '',
    labelMapping: {},
  };

  constructor(props) {
    super(props);

    this.videoRef = createRef();

    this.onBeforePlay = this.onBeforePlay.bind(this);
    this.onTime = this.onTime.bind(this);
    this.setComscoreMeta = this.setComscoreMeta.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onMeta = this.onMeta.bind(this);
    this.onBuffer = this.onBuffer.bind(this);
    this.onAdPlay = this.onAdPlay.bind(this);
    this.onAdPause = this.onAdPause.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onAdsManager = this.onAdsManager.bind(this);
    this.onAdError = this.onAdError.bind(this);
    this.onAdBreakStart = this.onAdBreakStart.bind(this);
    this.onAdBreakEnd = this.onAdBreakEnd.bind(this);
    this.onAdTime = this.onAdTime.bind(this);
    this.onFirstFrame = this.onFirstFrame.bind(this);
    this.onMetaCueParsed = this.onMetaCueParsed.bind(this);
    this.onCaptionsList = this.onCaptionsList.bind(this);
    this.onCaptionsChanged = this.onCaptionsChanged.bind(this);
    this.playerMountedCallback = this.playerMountedCallback.bind(this);
    this.playerUnmountingCallback = this.playerUnmountingCallback.bind(this);
    this.togglePlayPause = this.togglePlayPause.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.setVolume = this.setVolume.bind(this);
    this.seek = this.seek.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.setMouseEvent = this.setMouseEvent.bind(this);
    this.setTouchProgress = this.setTouchProgress.bind(this);
    this.toggleShare = this.toggleShare.bind(this);
    this.setTouchEvent = this.setTouchEvent.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);
    this.toggleSubtitles = this.toggleSubtitles.bind(this);
    this.enableSubtitleTrack = this.enableSubtitleTrack.bind(this);
    this.toggleSubtitleSettings = this.toggleSubtitleSettings.bind(this);
    this.setSubtitleSettingsTab = this.setSubtitleSettingsTab.bind(this);
    this.setSubtitleSettings = this.setSubtitleSettings.bind(this);
    this.removeTouchClass = this.removeTouchClass.bind(this);
    this.addTouchClass = this.addTouchClass.bind(this);
    this.setParselyMeta = this.setParselyMeta.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onError = this.onError.bind(this);
    this.onBufferFull = this.onBufferFull.bind(this);

    this.state = {
      adTrackingData: {
        durationInSeconds: 0,
      },
      isAd: false,
      adCountdown: 0,
      progress: 0,
      vidProgress: 0,
      isFullScreen: false,
      isPlaying: false,
      vidState: 'Loading',
      muted: false,
      volume: 100,
      seekPosition: 0,
      tooltipPosition: 0,
      tooltipVisible: false,
      showShareCard: false,
      showSubtitleSettings: false,
      subtitleSettingsTab: 'Presets',
      subtitleSettings: storedCaptionSettings || defaultSubtitleSettings,
      containerStyle: containerSettings(storedCaptionSettings),
      lineStyle: lineSettings(storedCaptionSettings),
      hasSubtitles: false,
      subtitleTracks: [],
      adDuration: 0,
      playlist: [],
      adClickThrough: '',
      captionFontSize: captionFontSizeSettings(storedCaptionSettings),
      mParticleID: '',
      playerWidth: 0,
      playerHeight: 0,
    };
  }

  async createSessionItem(reloaded) {
    const {
      video: {
        mediaId = '',
        videoId = '',
        isLivestream = '',
        m3uPid = '',
        m3u8Url = '',
        poster = '',
        title = '',
        summary = '',
        duration = 0,
        syndicated_id: syndicatedId = '',
        videoContentId = '',
        syndicatedCat = '',
        primaryCatID = 0,
        catNames = {},
      },
      video = {},
      continuousPlay = '',
      islead,
      fastSchedule = [],
    } = this.props;

    this.jwPlayerId = `jwplayer-${mediaId || videoId}-${Math.floor(
      Math.random() * 1001
    )}`;

    const {
      nbc: {
        brand,
        dataLayer: {
          division,
        },
      },
    } = window;

    const {
      videoRef,
    } = this;

    let network = ('telemundo' === brand) ? 'tlmd' : 'nbc';
    network = ('rsn' === division) ? 'rsn' : network;

    const vidId = mediaId || videoId;

    const base = 'https://link.theplatform.com/s/';

    const analyticsObject = {
      ...video,
      islead,
      network,
      continuousPlay,
    };

    const url = ('1' === isLivestream) ?
      // eslint-disable-next-line max-len
      `${m3uPid}?mbr=true&assetTypes=LegacyRelease&mode=LIVE&format=SMIL&tracking=true&formats=MPEG-DASH+widevine,M3U+appleHlsEncryption,M3U+none,MPEG-DASH+none,MPEG4,MP3&schema=2.0` :
      `media/${vidId}?formats=MPEG-DASH+widevine,M3U+appleHlsEncryption,M3U+none,MPEG-DASH+none,MPEG4,MP3&format=SMIL`;
    if (0 < m3u8Url.length) {
      this.setState({
        playlist: [{
          file: setXumoParameters({
            elem: videoRef.current,
            m3u8Url,
          }),
          fwassetid: formatSyndicatedID('' !== syndicatedId ? syndicatedId : videoContentId),
          image: poster,
          tracks: [],
          duration: 86400,
          isLivestream,
          mediaid: formatSyndicatedID('' !== syndicatedId ? syndicatedId : videoContentId),
          videoTitle: title,
          mpxTitle: title,
          meta: getAnalyticsMeta({ ...analyticsObject }),
          section: '',
          freewheel: {
            ...this.freewheel,
          },
          fastSchedule,
        }],
      });
    } else {
      await fetch(`${base}${this.getMPXAccount()}/${url}`)
        .then((response) => response.text())
        .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
        .then((data) => {
          const sideloadedCaptions: Captions[] = [];
          let mpxTitle = title;
          const textStreams = data.getElementsByTagName('textstream');
          const refAttribute = data.getElementsByTagName('ref');

          if (0 < refAttribute.length && refAttribute[0].getAttribute('title')) {
            mpxTitle = refAttribute[0].getAttribute('title') ?? '';
          }

          if (0 < textStreams.length) {
            const foundVTTStream = [].filter.call(textStreams, (stream) => 'text/vtt' === stream.getAttribute('type'));
            if (0 < foundVTTStream.length) {
              sideloadedCaptions.push({
                id: 'vtt',
                kind: 'captions',
                file: foundVTTStream[0].getAttribute('src'),
                label: getLanguage(brand, ''),
              });
            }
          }
          this.setState({
            subtitleTracks: sideloadedCaptions,
            playlist: [{
              file: data.getElementsByTagName('video')[0].getAttribute('src'),
              tracks: sideloadedCaptions,
              image: poster,
              mediaid: vidId,
              fwassetid: 'local' !== env ? videoId.toString() : '2009669699810',
              videoTitle: title,
              duration: '1' !== isLivestream ? duration : 600,
              isLivestream,
              mpxTitle,
              section: primaryCategory(syndicatedCat, primaryCatID, catNames),
              meta: getAnalyticsMeta({ ...analyticsObject }),
              freewheel: {
                ...this.freewheel,
              },
              fastSchedule,
            }],
          }, () => {
            const {
              playlist: nextUpVideo,
              muted,
            } = this.state;
            if (reloaded) {
              this.lastProgressUpdate = 0;
              this.player.load(nextUpVideo);
              this.player.setConfig({
                autostart: true,
                mute: muted,
              });

              if ('articleplayer' === playerType) {
                let newCaption;

                const captionContainer = document.getElementById(this.player.id)?.closest(
                  islead ? '.article-featured-media' : '[data-react-component]'
                );

                if (captionContainer) {
                  newCaption = islead ? captionContainer.querySelector('figcaption .caption') : captionContainer.nextSibling;
                }

                if (newCaption && ['caption', 'video_caption'].some((e) => newCaption.classList.contains(e))) {
                  ReactDOM.render(
                    <NBCTruncate
                      text={summary.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '')}
                      maxlines={2}
                      maxlinesmobile={4}
                      readmore={__('Read More', 'nbc')}
                    />,
                    newCaption
                  );
                }
              }
            }
          });
        });
    }
  }

  async componentDidMount() {
    const {
      autoplay,
      video: {
        isLivestream,
      },
    } = this.props;

    const mParticleID = await unifiedMpidCheck(this.onUnAuth);

    this.setState({
      mParticleID,
      playerWidth: this.videoRef.current?.clientWidth ?? 0,
      playerHeight: this.videoRef.current?.clientHeight ?? 0,
    });

    await this.createSessionItem(false);

    if (autoplay) {
      if ('undefined' === typeof nbc.video.autoPlayBlocked) {
        nbc.video.autoPlayBlocked = await isAutoplayBlocked(isLivestream);
      }

      this.setState({
        muted: true,
      });

      nbc.video.autoPlayBlocked = false;
    }
  }

  async componentDidUpdate(prevProps) {
    const { video: { videoId: currentVideoId } } = this.props;
    const { video: { videoId } } = prevProps;

    if (videoId !== currentVideoId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        vidState: 'Loading',
      });
      await this.createSessionItem(true);
    }
  }

  getMPXAccount() {
    const {
      props: {
        accountId,
        video: {
          videoContentId = '',
          syndicated_id: firstSyndicatedID = '',
        },
      },
    } = this;

    const {
      mpxAccounts = [],
    } = nbc || {};

    let syndicatedID = firstSyndicatedID;

    if ('' === syndicatedID && videoContentId) {
      syndicatedID = videoContentId;
    }

    // if there's a syndicated ID, use the parent site MPX Account ID.
    if (syndicatedID && 'string' === typeof syndicatedID && mpxAccounts) {
      const siteID = syndicatedID.split(':')[1];
      const accountIDs = Object.keys(mpxAccounts);
      for (let index = 0; index < accountIDs.length; index += 1) {
        const mpxAccountID = accountIDs[index];
        if (
          mpxAccounts
          && mpxAccountID
          && mpxAccounts[mpxAccountID]
          && mpxAccounts[mpxAccountID].includes(siteID)
        ) {
          return mpxAccountID;
        }
      }
    }

    return accountId || '';
  }

  setParselyMeta() {
    /**
     * Updates Parsely Metadata.
     *
     * @returns none
     */

    const {
      video: {
        title,
        mediaId,
        duration,
        poster,
        tagNames = [],
        syndicated_id: syndicatedId = '',
        videoContentId = '',
        syndicatedCat = '',
        primaryCatID = 0,
        catNames = {},
      },
    } = this.props;

    // convert pubdate to milliseconds
    const epochMilliseconds = Date.parse(pubdate);

    // convert object to array
    const tagNamesArray = Object.values(tagNames);

    const metaOverrides = {
      title,
      duration,
      video_platform: 'jwplayer',
      image_url: poster,
      tags: tagNamesArray,
      pub_date_tmsp: epochMilliseconds,
      authors: [author],
      section: primaryCategory(syndicatedCat, primaryCatID, catNames),
    };

    const syndicatedID = formatSyndicatedID('' !== syndicatedId ? syndicatedId : videoContentId);

    PARSELY.video.trackPlay('' !== mediaId ? mediaId : syndicatedID, metaOverrides);
  }

  /**
   * Determine if tlmd or rsn, mobile, and article lead or video landing.
   * Formerly mobileAutoplayMute, but no longer mobile-specific.
   *
   * @returns {boolean}
   */

  autoplayMute() {
    const {
      props: {
        autoplay,
        islead,
        placeholder = false,
      },
    } = this;

    const muteParam = getUrlParam('mute', '');

    if (isAmpVideo && '' !== muteParam) {
      return 'true' === muteParam;
    }

    if (autoplay && ! placeholder) {
      return true;
    }

    // all stations + rsn autoplay on mute for articles and video landing.
    if (islead &&
    'article - general' === template) {
      return true;
    }

    // all stations + rsn require autoplay mute on video landing as well.
    if ('video landing' === template) {
      return true;
    }

    // all stations + rsn require autoplay mute on weather landing also.
    if ('weather landing' === template) {
      return true;
    }

    return false;
  }

  // Registers players as they mount
  playerMountedCallback({ player }) {
    this.player = player;
  }

  // Using resize to detect fullscreen since we're not expanding the video element
  onResize(event) {
    this.setState({
      isFullScreen: 0 === event.width,
    });
  }

  // Nulls registered players as they unmount
  playerUnmountingCallback() {
    this.player = null;
  }

  onError(event) {
    console.log(event, 'On Player Error');
  }

  onBufferFull(event) {
    console.log(event, 'On Player Buffer Full');
  }

  onBeforePlay() {
    console.log(this.player.getCurrentCaptions(), 'onBeforePlay test');
  }

  onBuffer() {
    this.setState({
      vidState: 'Loading',
    });
  }

  onPlay() {
    this.setState({
      muted: this.player?.getMute(),
      vidState: 'Playing',
      isPlaying: true,
    });
  }

  onPause() {
    this.setState({
      vidState: 'Paused',
      isPlaying: false,
    });
  }

  onAdPlay() {
    console.log('playing ad');
    this.setState({
      muted: this.player?.getMute(),
      vidState: 'Playing',
      isPlaying: true,
      isAd: true,
    });
  }

  onAdPause() {
    this.setState({
      vidState: 'Paused',
      isPlaying: false,
    });
  }

  onFirstFrame(event) {
    console.log(event, 'TEST FIRST FRAME EVENT');
    this.setParselyMeta();
  }

  onMeta(event) {
    console.log(event, 'TEST META EVENT');
  }

  onMetaCueParsed(event) {
    console.log(event, 'TEST META CUE PARSE EVENT');
  }

  onTime(event) {
    const {
      currentTime,
    } = event;

    const now = Date.now();

    if (1000 <= (now - this.lastProgressUpdate)) {
      this.lastProgressUpdate = now;
      this.setState({
        progress: currentTime,
        vidProgress: currentTime,
      });
    }
  }

  onComplete() {
    const {
      onSessionEnded,
    } = this.props;

    if ('function' === typeof onSessionEnded) {
      onSessionEnded();
    }
  }

  onAdError(e) {
    console.error(e, 'on ad error');
    this.setState({
      isAd: false,
      adDuration: 0,
    });
  }

  onAdBreakStart(e) {
    console.log(e, 'on ad break start');
    this.setState({
      isAd: true,
      isPlaying: true,
    });
  }

  onAdBreakEnd(e) {
    console.log(e, 'on ad break end');
    this.setState({
      isAd: false,
      adDuration: 0,
    });
  }

  onAdsManager(adManager) {
    console.log(adManager);
  }

  onAdTime(event) {
    const {
      duration,
      position,
    } = event;

    const now = Date.now();

    if (1000 <= (now - this.lastProgressUpdate)) {
      this.lastProgressUpdate = now;
      this.setState({
        progress: position,
        adDuration: duration,
        adCountdown: duration - position,
      });
    }
  }

  onUnAuth = (mParticleID) => {
    this.setState({
      mParticleID,
    });
  };

  setComscoreMeta() {
    const comscoreC4Value = (null !== comscoreC4) ? comscoreC4 : nbcMarket;

    this.comscoreMeta = {
      publisherId: '6035083',
      labelMapping: {
        c4: `"${decodeURI(getUrlParam('c4', comscoreC4Value))}"`,
        c6: '"*null"',
        ns_st_pu: '"NBC Owned Television Stations"',
        ns_st_ge: '"News"',
        ns_st_st: `"${callLetters}"`,
      },
    };

    this.comscoreMeta.labelMapping = Object.entries(
      this.comscoreMeta.labelMapping
    )
      .map((item) => `${item[0]}=${item[1]}`)
      .join(',');

    console.log(this.comscoreMeta);

    if ('function' === typeof ComscoreJWP) {
      ComscoreJWP(
        this.player,
        this.comscoreMeta,
      );
    }
  }

  onReady() {
    this.setState({
      vidState: 'Ready',
    });

    const {
      video: {
        duration,
      },
    } = this.props;

    const {
      adTrackingData: {
        durationInSeconds: adDuration,
      },
    } = this.state;

    if (0 < adDuration) {
      this.setState({
        isAd: adDuration < duration,
      });
    }

    this.setComscoreMeta();
  }

  onCaptionsList(subtitles) {
    console.log('list of available subtitles: ', subtitles);

    const {
      state: {
        subtitleTracks: availableSubtitles,
      },
    } = this;

    if (0 < availableSubtitles.length) {
      this.setState({
        hasSubtitles: 0 < this.player.getCurrentCaptions(),
      });

      return;
    }

    const {
      tracks: subtitleSet,
    } = subtitles;

    const resultsArray: Captions[] = [];

    if (1 < subtitleSet.length) {
      const foundSubtitleTrack = subtitleSet.find(({ id }) =>
        ['default', 'nativesubtitles0', 'textTrack1'].includes(id) ||
        (isSafari && ['nativecaptions0', 'nativecaptions1'].includes(id)));

      if (foundSubtitleTrack) {
        resultsArray.push(foundSubtitleTrack);
      }
    }

    this.setState({
      subtitleTracks: resultsArray,
    });
  }

  onCaptionsChanged(event) {
    const {
      track,
    } = event;

    if (0 < track) {
      const checkTrack = prioritizeNativeCaption(track, this.player?.getCaptionsList());
      if (track !== checkTrack) {
        this.player?.setCurrentCaptions(checkTrack);
      }
      this.setState({
        hasSubtitles: true,
      });
    }
  }

  toggleMute() {
    const { muted } = this.state;

    this.player?.setMute(! muted);

    if (isLiveBlog) {
      liveBlogTracking(muted ? 'unmute' : 'mute', 'video click', this.jwPlayerId);
    }

    this.setState({
      muted: ! muted,
    });
  }

  setVolume(volume) {
    const { muted } = this.state;

    this.player?.setVolume(volume);

    if (muted && 0 < volume) {
      this.player?.setMute(false);
    }

    this.setState({
      volume,
      muted: ! (0 < volume),
    });
  }

  togglePlayPause() {
    const {
      isPlaying,
    } = this.state;

    if (isPlaying) {
      this.player?.pause();
    } else {
      this.player?.play();
    }

    if (isLiveBlog) {
      liveBlogTracking(isPlaying ? 'pause' : 'play', 'video click', this.jwPlayerId);
    }

    this.setState({
      isPlaying: ! isPlaying,
      vidState: isPlaying ? 'Paused' : 'Playing',
    });
  }

  seek() {
    const { seekPosition } = this.state;

    this.player?.seek(seekPosition);
  }

  setProgress(event) {
    const {
      video: {
        duration,
      },
    } = this.props;

    const {
      adTrackingData: {
        durationInSeconds: adDuration = 0,
      },
    } = this.state;

    const vidDuration = 0 < duration ? duration : this.player?.getDuration();
    const element = event.target;
    const skipTo = Math.round((event.nativeEvent.offsetX / element.clientWidth) * (vidDuration));
    const rect = this.videoRef.current?.getBoundingClientRect().left ?? 0;

    this.setState({
      seekPosition: skipTo + adDuration + 0.1,
      tooltipPosition: event.pageX - rect,
    });
  }

  toggleShare(storedPlayState) {
    const {
      showShareCard,
    } = this.state;

    this.setState({
      showShareCard: ! showShareCard,
    }, () => {
      if (storedPlayState) {
        this.togglePlayPause();
      }
    });

    if (isLiveBlog) {
      liveBlogTracking(! showShareCard ? 'menu' : 'share close', 'video click', this.jwPlayerId);
    }
  }

  setTouchProgress(event) {
    const {
      video: {
        duration,
      },
    } = this.props;

    const {
      adTrackingData: {
        durationInSeconds: adDuration = 0,
      },
    } = this.state;

    const vidDuration = 0 < duration ? duration : this.player?.getDuration();
    const element = event.target;
    const rect = this.videoRef.current?.getBoundingClientRect().left ?? 0;
    const offSetX = event.nativeEvent.touches[0].pageX - rect;
    const skipTo = Math.round((offSetX / element.clientWidth) * (vidDuration));

    this.setState({
      seekPosition: skipTo + adDuration + 0.1,
      tooltipPosition: event.targetTouches[0].pageX - rect,
    }, () => {
      this.seek();
    });
  }

  setMouseEvent(event) {
    switch (event.type) {
      case 'mouseup':
      case 'mouseout':
        setTimeout(() => {
          this.setState({
            tooltipVisible: false,
          });
        }, 500);
        break;

      default:
        this.setState({
          tooltipVisible: true,
        });
    }
  }

  setTouchEvent(event) {
    switch (event.type) {
      case 'touchend':
      case 'touchcancel':
        setTimeout(() => {
          this.setState({
            tooltipVisible: false,
          });
        }, 500);
        break;

      default:
        this.setState({
          tooltipVisible: true,
        });
    }
  }

  removeTouchClass() {
    const playerElement = this.videoRef.current?.closest('.player');
    if (playerElement && playerElement.classList.contains('player--no-touch')) {
      playerElement.classList.remove('player--no-touch');
    }
  }

  addTouchClass() {
    const playerElement = this.videoRef.current?.closest('.player');
    if (playerElement && ! playerElement.classList.contains('player--no-touch')) {
      setTimeout(() => {
        playerElement.classList.add('player--no-touch');
      }, 2000);
    }
  }

  toggleFullScreen() {
    let videoElement = this.videoRef.current?.closest('.video-playlist__container');

    if (isIOS()) {
      const videoAsset = this.videoRef.current?.querySelector('.jw-video');
      (videoAsset as any)?.webkitEnterFullScreen();
      if (isLiveBlog) {
        liveBlogTracking('full screen', 'video click', this.jwPlayerId);
      }
      return;
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
      if (isLiveBlog) {
        liveBlogTracking('collapse screen', 'video click', this.jwPlayerId);
      }
    } else {
      if (null === videoElement) {
        videoElement = this.videoRef.current?.closest('#coziPlayer');
      }
      videoElement?.requestFullscreen({ navigationUI: 'hide' });
      if (isLiveBlog) {
        liveBlogTracking('full screen', 'video click', this.jwPlayerId);
      }
    }
  }

  toggleSubtitleSettings(storedPlayState) {
    const {
      showSubtitleSettings,
    } = this.state;

    this.setState({
      showSubtitleSettings: ! showSubtitleSettings,
    }, () => {
      if (storedPlayState) {
        this.togglePlayPause();
      }
    });
  }

  toggleSubtitles() {
    const {
      hasSubtitles,
    } = this.state;

    if (! hasSubtitles) {
      this.player?.setCurrentCaptions(prioritizeNativeCaption(1, this.player?.getCaptionsList()));
    } else {
      this.player?.setCurrentCaptions(0);
    }

    if (isLiveBlog) {
      liveBlogTracking('closed caption', 'video click', this.jwPlayerId);
    }

    this.setState({
      hasSubtitles: ! hasSubtitles,
    });
  }

  enableSubtitleTrack(id) {
    this.player?.setCurrentCaptions(prioritizeNativeCaption(id, this.player?.getCaptionsList()));

    this.setState({
      hasSubtitles: true,
    });
  }

  setSubtitleSettingsTab(tab) {
    this.setState({
      subtitleSettingsTab: tab,
    });
  }

  setSubtitleSettings(settings) {
    lscache.set('captionSettings', settings);
    const {
      borderSettings: {
        effect: borderEffect,
      },
      backgroundSettings: {
        color: backgroundColor,
        opacity: backgroundOpacity,
      },
      textSettings: {
        color: textColor,
        opacity: fontOpacity,
        font: textFont,
        size: textSize,
      },
    } = settings;

    const {
      textColors,
      textFonts,
      textSizesMapping,
    } = textMap;

    this.player?.setCaptions({
      backgroundColor: backgroundColorMap[backgroundColor],
      backgroundOpacity: opacityMap[backgroundOpacity],
      color: textColors[textColor],
      fontFamily: textFonts[textFont],
      fontSize: textSizesMapping[textSize],
      edgeStyle: borderEffect,
      fontOpacity: opacityMap[fontOpacity],
    });

    this.setState({
      subtitleSettings: settings,
      lineStyle: lineSettings(settings),
      containerStyle: containerSettings(settings),
      captionFontSize: textSizesMapping[textSize],
    });
  }

  render() {
    const {
      autoplay,
      brand,
      host,
      twitter,
      islead,
      video: {
        isLivestream,
        mediaId,
        videoId,
        videoContentId,
        poster,
        duration,
        url,
        title,
        sensitivity = '',
        m3u8Url = '',
        disableEmbed = false,
      },
      hideShare = false,
    } = this.props;

    const {
      isAd,
      vidProgress,
      adCountdown,
      progress,
      isFullScreen,
      isPlaying,
      vidState,
      muted,
      volume,
      seekPosition,
      tooltipPosition,
      tooltipVisible,
      showSubtitleSettings,
      subtitleSettingsTab,
      subtitleSettings,
      showShareCard,
      lineStyle,
      containerStyle,
      subtitleTracks,
      hasSubtitles,
      adDuration,
      playlist,
      adClickThrough,
      captionFontSize,
      mParticleID,
      playerWidth,
      playerHeight,
    } = this.state;

    const vidDuration = 0 < duration ? duration : this.player?.getDuration();
    const profileID = `${fwNetworkID}:ots${fwSiteKey}${isMobile ? '_mobile_js' : '_desktop_html'}`;
    const fwUniProfileID = `${fwNetworkID}:nbcu_ots_web_${'1' === isLivestream ? 'live' : 'vod'}`;

    const fwSFIDVodPlatform = isMobile ? fwMobileSFIDVod : fwDesktopSFIDVod;
    const fwSFIDLivePlatform = isMobile ? fwMobileSFIDLive : fwDesktopSFIDLive;

    this.freewheel = fwunification ? {
      networkid: fwNetworkID,
      serverid: 'https://29773.v.fwmrm.net/ad/g/1',
      profileid: fwUniProfileID,
      sectionid: setFreewheelCSID('1' === isLivestream),
      adManagerURL: `https://mssl.fwmrm.net/libs/adm/${fwAdManagerVersion}/AdManager.js`,
      vcid: mParticleID,
      vdur: '1' !== isLivestream ? vidDuration : 600,
      sfid: '1' === isLivestream ? fwSFIDLivePlatform : fwSFIDVodPlatform,
      afid: fwAFID,
      capabilities: {
        flag: {
          play: 'on',
          uapl: 'off',
          sltp: 'on',
          emcr: 'on',
          unka: 'on',
          unks: 'on',
          fbad: 'on',
          slcb: 'on',
          nucr: 'on',
          aeti: 'on',
          rema: 'on',
          vicb: 'on',
        },
      },
      custom: {
        _fw_vcid2: mParticleID,
        _fw_player_width: playerWidth.toString(),
        _fw_player_height: playerHeight.toString(),
        sensitivecontent: encodeURIComponent(sensitivity),
        mode: `${'1' === isLivestream ? 'live' : 'on-demand'}`,
        metr: '1031',
        ...setContentSections(url),
      },
    } : {
      networkid: fwNetworkID,
      serverid: 'https://5D4A1.v.fwmrm.net/ad/g/1',
      profileid: profileID,
      sectionid: `${siteSectionId}${getFwSSIDSuffix()}`,
      adManagerURL: `https://mssl.fwmrm.net/libs/adm/${fwAdManagerVersion}/AdManager.js`,
      vcid: mParticleID,
      capabilities: {
        flag: {
          amcb: 'on',
          dtrd: 'off',
        },
      },
      custom: {
        _fw_vcid2: mParticleID,
        _fw_player_width: playerWidth.toString(),
        _fw_player_height: playerHeight.toString(),
        sensitivecontent: sensitiveContent.toString(),
      },
    };

    const noPre = {
      autoplayadsmuted: true,
    };

    const advertising = {
      client: 'freewheel',
      autoplayadsmuted: true,
      schedule: {
        offset: 'pre',
        tag: 'fw-preroll',
      },
      freewheel: {
        ...this.freewheel,
      },
    };

    const isFeature = 'article - featured' === template;
    const isVideoHub = 'video landing' === template;

    return (
      <div
        className={`player${(isAd) ? ' advertisement' : ''}${((isMobile || isIpad()) && isMobileDevice) ? ' player--no-touch' : ''}`}
        ref={this.videoRef}
        onTouchStart={this.removeTouchClass}
        onTouchEnd={this.addTouchClass}
      >
        {showShareCard &&
          <ShareCard
            url={url}
            host={host}
            title={title}
            videoContentId={videoContentId}
            videoId={videoId}
            toggleShare={this.toggleShare}
            twitter={twitter}
            isPlaying={isPlaying}
            isLiveBlog={isLiveBlog}
            liveBlogTracking={liveBlogTracking}
            jwPlayerId={this.jwPlayerId}
            disableEmbed={disableEmbed}
          />
        }
        {showSubtitleSettings &&
          <SubtitleSettings
            toggleSubtitleSettings={this.toggleSubtitleSettings}
            setSubtitleSettings={this.setSubtitleSettings}
            setSubtitleSettingsTab={this.setSubtitleSettingsTab}
            isPlaying={isPlaying}
            lineStyle={lineStyle}
            containerStyle={containerStyle}
            subtitleSettingsTab={subtitleSettingsTab}
            subtitleSettings={subtitleSettings}
          />
        }
        {'Loading' === vidState &&
          // <div className={`player__loading ${'Loading' === vidState && 'player__loading--empty'}`} />
          <div className="player__loading" />
        }
        <div
          className={`player__overlay${isPlaying ? ' player__overlay--playing' : ' player__overlay--paused'}${isAd ? ' player__overlay--ad' : ''}`}
          onClick={() => {
            if ('Loading' !== vidState) {
              if ('1' !== isLivestream && ! isAd) {
                this.togglePlayPause();
              }
              if ('' !== adClickThrough && isAd) {
                window.open(
                  adClickThrough,
                  '_blank'
                );
              }
            }
          }}
          role="button"
          tabIndex={0}
          onKeyUp={(e) => {
            if (' ' === e.key && 'Loading' !== vidState) {
              if ('1' !== isLivestream && ! isAd) {
                this.togglePlayPause();
              }
            }
          }}
        >
          {('Paused' === vidState || 'Ready' === vidState) &&
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                this.togglePlayPause();
              }}
              className="player__play-button--large"
            >
              Play
            </button>
          }
          {(poster && ('Loading' === vidState || 'Ready' === vidState) && ! islead) &&
            <img
              src={poster}
              className="player__poster"
              alt={title}
            />
          }
        </div>
        <div
          className="player__controls"
        >
          {isAd &&
            <div className="player__progress">
              <progress
                className="player__progressbar"
                value={progress}
                max={adDuration}
              />
              <input
                className="player__seekbar"
                id="seek"
                value={progress}
                min="0"
                max={adDuration}
                type="range"
                step="0.5"
              />
            </div>
          }
          {'1' !== isLivestream && ! isAd &&
            <div className="player__progress">
              <progress
                className="player__progressbar"
                value={vidProgress}
                max={vidDuration}
              />
              <input
                className="player__seekbar"
                id="seek"
                value={progress}
                min="0"
                max={vidDuration}
                type="range"
                step=".5"
                onInput={this.seek}
                onMouseMove={
                  (e) => {
                    this.setProgress(e);
                    this.setMouseEvent(e);
                  }
                }
                onMouseOut={(e) => { this.setMouseEvent(e); }}
                onMouseUp={(e) => { this.setMouseEvent(e); }}
                onBlur={(e) => { this.setMouseEvent(e); }}
                onTouchMove={
                  (e) => {
                    this.setTouchProgress(e);
                    this.setTouchEvent(e);
                  }
                }
                onTouchStart={
                  (e) => {
                    this.setTouchProgress(e);
                    this.setTouchEvent(e);
                  }
                }
                onTouchEnd={(e) => { this.setTouchEvent(e); }}
                onTouchCancel={(e) => { this.setTouchEvent(e); }}
              />
              <div
                className={`player__tooltip ${tooltipVisible && 'player__tooltip-show'}`}
                style={
                  { left: `${tooltipPosition - 15}px` }
                }
              >
                {(0 > parseInt(formatTime(seekPosition - adDuration), 10) ? '' : formatTime(seekPosition - adDuration))}
              </div>
            </div>
          }
          <div className="player__controls--left">
            {('1' !== isLivestream || isAd) &&
              <button
                type="button"
                onClick={this.togglePlayPause}
                className={`player__play-button ${isPlaying && 'player__play-button--pause'}`}
              >
                {isPlaying ? 'Pause' : 'Play'}
              </button>
            }
            {'1' === isLivestream && isAd &&
              <div className="player__time">
                {`Ad: ${formatTime(adCountdown)}`}
              </div>
            }
            {'1' !== isLivestream && 0 < vidDuration &&
              <div className="player__time">
                {isAd ?
                  `Ad: ${formatTime(adCountdown)}`
                  :
                  `${formatTime(vidProgress)} / ${formatTime(vidDuration)}`
                }
              </div>
            }
            {'1' === isLivestream && ! isAd &&
              <div className="player__live">{__('Live', 'nbc')}</div>
            }
          </div>
          <div className="player__controls--right">
            <div className="player__volume-controls">
              <button
                type="button"
                className={`player__mute-button ${muted && 'player__mute-button--muted'}`}
                onClick={this.toggleMute}
              >
                {muted ? 'Unmute' : 'Mute'}
              </button>
              <div className="player__volume-input">
                <input
                  className="player__volume-slider"
                  type="range"
                  min="0"
                  max="100"
                  value={muted ? 0 : volume}
                  step="1"
                  onInput={({ target }) => {
                    const { value } = target as HTMLInputElement;
                    this.setVolume(parseFloat(value));
                  }}
                />
              </div>
            </div>
            {0 < subtitleTracks.length && ! isAd &&
              <div className="player__subtitle-controls">
                <button
                  type="button"
                  className={`player__subtitles-button ${hasSubtitles && 'player__subtitles-button--on'}`}
                  onClick={this.toggleSubtitles}
                >
                  Subtitles
                </button>
                <div className="player__subtitles-menu">
                  {
                    subtitleTracks.map(({ id }) =>
                      <button
                        className={`player__subtitles-option ${hasSubtitles && 'player__subtitles-option--selected'}`}
                        type="button"
                        onClick={() => {
                          this.enableSubtitleTrack(1);
                          this.setState({
                            hasSubtitles: true,
                          });
                        }}
                      >
                        {getLanguage(brand, id)}
                      </button>)
                  }
                  <button
                    className={`player__subtitles-option ${! hasSubtitles && 'player__subtitles-option--selected'}`}
                    type="button"
                    onClick={() => {
                      this.player?.setCurrentCaptions(0);
                      this.setState({
                        hasSubtitles: false,
                      });
                    }}
                  >
                    {__('None', 'nbc')}
                  </button>
                  <button
                    className="player__subtitles-option"
                    type="button"
                    onClick={() => {
                      this.toggleSubtitleSettings(isPlaying);
                    }}
                  >
                    {__('Settings', 'nbc')}
                  </button>
                </div>
              </div>
            }
            {! hideShare &&
              <button
                type="button"
                title="Share"
                onClick={(e) => {
                  e.stopPropagation();
                  this.toggleShare(isPlaying);
                }}
                className="player__share-button"
              >
                Share
              </button>
            }
            {! isAd &&
              <button
                type="button"
                title={isFullScreen ? 'Collapse' : 'Expand'}
                onClick={this.toggleFullScreen}
                className={`player__fullscreen-button ${isFullScreen && 'player__fullscreen-button--collapse'}`}
              >
                Toggle Fullscreen
              </button>
            }
          </div>
        </div>
        <div
          className={`player__container ${captionFontSize}`}
        >
          {0 < playlist.length &&
          <JWPlayer
            id={this.jwPlayerId}
            config={{
              key: jwPlayerKey,
              width: this.videoRef.current?.clientWidth ?? 848,
              height: this.videoRef.current?.clientHeight ?? 477,
              controls: false,
            }}
            onError={this.onError}
            onBufferFull={this.onBufferFull}
            onAdError={this.onAdError}
            onAdBreakStart={this.onAdBreakStart}
            onAdBreakEnd={this.onAdBreakEnd}
            onBeforePlay={this.onBeforePlay}
            onAdTime={this.onAdTime}
            onTime={this.onTime}
            onReady={this.onReady}
            onAdsManager={this.onAdsManager}
            onMeta={this.onMeta}
            onFirstFrame={this.onFirstFrame}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onAdPlay={this.onAdPlay}
            onAdPause={this.onAdPause}
            onBuffer={this.onBuffer}
            onComplete={this.onComplete}
            onCaptionsList={this.onCaptionsList}
            onCaptionsChanged={this.onCaptionsChanged}
            onMetadataCueParsed={this.onMetaCueParsed}
            didMountCallback={this.playerMountedCallback}
            willUnmountCallback={this.playerUnmountingCallback}
            onResize={this.onResize}
            plugins={{
              '/wp-content/themes/nbc-station/static/video/videoHeartbeat/JWHeartbeat.js?ver=1.2.2.Z': {
                adobeTrackingDomain: 'https://nbcume.hb.omtrdc.net',
                channelName: getUrlParam('callletters', callLetters).toUpperCase(),
                channel: getUrlParam('callletters', callLetters),
                pageName,
                pid: mediaId || videoId,
                debug: true,
              },
            }}
            library="/wp-content/themes/nbc-station/static/video/jwplayer/jwplayer.8.30.0.js?ver=1.0.3"
            playlist={playlist}
            autoPause={{
              viewability:
                '1' !== isLivestream &&
                ! isMobile &&
                ! isFeature &&
                ! isVideoHub &&
                ! isAmpVideo,
            }}
            autostart={autoplay}
            fwassetid={'local' !== env ? videoId.toString() : '2009669699810'}
            mute={this.autoplayMute()}
            advertising={0 < m3u8Url.length ? noPre : advertising}
          />
          }
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
